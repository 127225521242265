import {
  AdminPromotionDeactivateReason,
  AdminPromotionDetailStatus,
  AdminPromotionStatus,
} from "../../../types/forwarding/adminPromotion";

/**
 * 프로모션 비활성화 사유를 한국어로 변환합니다.
 *
 * @param value - AdminPromotionDeactivateReason 또는 null 값
 * @returns 변환된 한국어 문자열
 */
export const changePromotionDeactivateReasonToKr = (
  value: AdminPromotionDeactivateReason | null
) => {
  switch (value) {
    case "freightTypeLcl":
      return "LCL";
    case "freightTypeAir":
      return "AIR";
    case "previousUser":
      return "쉽다 이용고객";
    case "specialContainer":
      return "특수컨테이너";
    default:
      return "-";
  }
};

/**
 * 프로모션 상세 상태를 한국어로 변환합니다.
 *
 * @param value - AdminPromotionDetailStatus 또는 null 값
 * @returns 변환된 한국어 문자열
 */
export const changePromotionDetailStatusToKr = (
  value: AdminPromotionDetailStatus | null
) => {
  switch (value) {
    case "waiting":
      return "견적서 요청 중";
    case "missed":
      return "부재 중";
    case "unableQuotation":
      return "수입견적 제출불가";
    case "lostContact":
      return "연락 두절";
    case "cancellationRequest":
      return "고객의 취소요청";
    case "duplicate":
      return "중복신청";
    case "etc":
    default:
      return "-";
  }
};

/**
 * 프로모션 상태를 한국어로 변환합니다.
 *
 * @param value - AdminPromotionStatus 값
 * @returns 변환된 한국어 문자열
 */
export const changePromotionStatusToKr = (value: AdminPromotionStatus) => {
  switch (value) {
    case "inProgress":
      return "진행";
    case "waiting":
      return "대기";
    case "pending":
      return "보류";
    case "finished":
      return "완료";
    case "canceled":
      return "취소";
    default:
      return "-";
  }
};

import { FreightType, Port } from "../../../types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  ShipmentCreateContainersInfo,
  ShipmentCreateProductsInfo,
} from "../../../types/forwarding/adminBidCreate";

/**
 * 주어진 portName에 해당하는 항구의 ID를 반환한다.
 *
 * @param portList - 항구 목록
 * @param portName - 항구 이름
 * @returns 항구 ID, 항구를 찾지 못한 경우 0을 반환
 */
function getPortId(portList: Port[] | undefined, portName: string | undefined) {
  return (
    portList?.find((v) => {
      return (
        `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
        portName
      );
    })?.id || 0
  );
}

/**
 * 주어진 portName에 해당하는 항구의 국가를 반환한다.
 *
 * @param portList - 항구 목록
 * @param portName - 항구 이름
 * @returns 항구 국가, 항구를 찾지 못한 경우 빈 문자열을 반환
 */
function getCountryOfPort(
  portList: Port[] | undefined,
  portName: string | undefined
) {
  return (
    portList?.find((v) => {
      return (
        `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
        portName
      );
    })?.country || ""
  );
}

/**
 * 주어진 portName에 해당하는 항구 항목을 반환한다.
 *
 * @param portList - 항구 목록
 * @param portName - 항구 이름
 * @returns 항구 항목, 항구를 찾지 못한 경우 undefined를 반환
 */
function findPortItem(
  portList: Port[] | undefined,
  portName: string | undefined
) {
  return portList?.find((v) => {
    return (
      `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
      portName
    );
  });
}

/**
 * 의뢰 생성에서 페이지 이동 시 수정된 입력이 반영된 새로운 데이터를 리턴한다.
 *
 * @param sessionStorageShipmentCreateInfo - 세션 스토리지에 저장된 의뢰 생성 정보
 * @param formIndex - 폼 인덱스
 * @param formData - 폼 데이터
 * @param disabled - 의뢰 생성이 완료된 탭에 대한 disabled 처리 값
 * @returns 수정된 입력이 반영된 새로운 의뢰 생성 정보
 */
function getNewShipmentCreateInfo({
  sessionStorageShipmentCreateInfo,
  formIndex,
  formData,
  disabled,
}: {
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  formIndex: number;
  formData: BidCreateFormData;
  /** 의뢰 생성이 복수인 경우 생성이 완료된 탭에 disabled처리를 위한 값 */
  disabled: boolean;
}) {
  const shallowCopiedShipmentCreateInfo = {
    ...sessionStorageShipmentCreateInfo,
  };

  const bidCreateData =
    shallowCopiedShipmentCreateInfo.bidCreateData[formIndex];

  shallowCopiedShipmentCreateInfo.bidCreateData[formIndex] = {
    ...bidCreateData,
    bidCreateFormData: formData,
    disabled,
  };

  return shallowCopiedShipmentCreateInfo;
}

/**
 * 의뢰 생성 요청 후 성공 핸들러
 *
 * @param hasPO - PO 여부
 * @param sessionStorageShipmentCreateInfo - 세션 스토리지에 저장된 의뢰 생성 정보
 * @param formIndex - 폼 인덱스
 * @param formData - 폼 데이터
 * @param handleMoveToOrderPage - 발주 페이지로 이동하는 함수
 * @param handleMoveToShipmentCreatePage - 의뢰 생성 페이지로 이동하는 함수 (선택 사항)
 * @param handleMoveToShipmentDetailPage - 의뢰 상세 페이지로 이동하는 함수
 * @param handleSessionStorageShipmentCreateInfoChange - 세션 스토리지 의뢰 생성 정보 변경 핸들러
 * @returns void
 */
const handleShipmentCreateSuccess = ({
  hasPO,
  sessionStorageShipmentCreateInfo,
  formIndex,
  formData,
  handleMoveToOrderPage,
  handleMoveToShipmentCreatePage,
  handleMoveToShipmentDetailPage,
  handleSessionStorageShipmentCreateInfoChange,
}: {
  hasPO: boolean | undefined;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  formIndex: number;
  formData: BidCreateFormData;
  handleMoveToOrderPage: () => void;
  handleMoveToShipmentCreatePage?: () => void;
  handleMoveToShipmentDetailPage: () => void;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
}) => {
  if (hasPO) {
    const newBidCreateInfo = getNewShipmentCreateInfo({
      sessionStorageShipmentCreateInfo: sessionStorageShipmentCreateInfo,
      formIndex,
      formData,
      disabled: true,
    });

    const isAllDisabled = newBidCreateInfo.bidCreateData.every((v) => {
      return v.disabled;
    });

    /** 모든 의뢰를 생성하는 경우 isAllDisabled는 true 발주 페이지로 이동함 */
    if (isAllDisabled) {
      handleMoveToOrderPage();
      return;
    }

    handleSessionStorageShipmentCreateInfoChange({
      ...newBidCreateInfo,
      /** 다음 의뢰 생성 탭으로 넘어가기 위해 + 1를 추가 */
      tabsDefaultValue: formIndex + 1,
    });

    /** 의뢰 생성이 복수인 경우 견적서 페이지에서 다시 의뢰 생성 페이지로 가야함 */
    handleMoveToShipmentCreatePage && handleMoveToShipmentCreatePage();

    return;
  }

  handleMoveToShipmentDetailPage();
};

/**
 * 주어진 freightType에 따라 컨테이너 또는 제품의 이름 목록을 반환한다.
 *
 * @param freightType - 화물 유형 (FCL 또는 LCL)
 * @param containersInfo - 컨테이너 정보 목록
 * @param productsInfo - 제품 정보 목록
 * @returns 이름 목록, 해당하는 정보가 없는 경우 undefined를 반환
 */
const getItemNameList = ({
  freightType,
  containersInfo,
  productsInfo,
}: {
  freightType: FreightType;
  containersInfo: ShipmentCreateContainersInfo[] | undefined;
  productsInfo: ShipmentCreateProductsInfo[] | undefined;
}) => {
  if (freightType === "FCL") {
    return containersInfo?.map((v) => {
      return v.name;
    });
  }

  return productsInfo?.map((v) => {
    return v.name;
  });
};

/**
 * productsInfo에서 isDouble(2단 적재)여부가 true인지 확인한다.
 *
 * @param productsInfo - 제품 정보 목록
 * @returns isDouble 값이 true인 경우 true, 그렇지 않은 경우 false
 */
const checkIsDoubleFromProductsInfo = ({
  productsInfo,
}: {
  productsInfo: ShipmentCreateProductsInfo[] | undefined;
}) => {
  if (productsInfo) {
    return productsInfo.some((v) => {
      return v.isDouble;
    });
  }

  return false;
};

/**
 * productsInfo의 총 quantity값을 리턴한다.
 *
 * @param productsInfo - 제품 정보 목록
 * @returns 총 quantity 값, 제품 정보 목록이 없는 경우 0을 반환
 */
const getProductInfoTotalQuantity = ({
  productsInfo,
}: {
  productsInfo: ShipmentCreateProductsInfo[] | undefined;
}) => {
  if (productsInfo) {
    return productsInfo.reduce((acc, cur) => {
      acc += cur.quantity;

      return acc;
    }, 0);
  }

  return 0;
};

export {
  getPortId,
  getCountryOfPort,
  findPortItem,
  getNewShipmentCreateInfo,
  handleShipmentCreateSuccess,
  checkIsDoubleFromProductsInfo,
  getProductInfoTotalQuantity,
  getItemNameList,
};

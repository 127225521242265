import {
  InvoiceResult,
  TrelloBidManagement,
} from "../../../types/forwarding/trello";

/**
 * 인보이스 결과를 한국어로 변환합니다.
 *
 * @param invoiceResult - 인보이스 결과 (InvoiceResult 타입)
 * @returns 한국어로 변환된 인보이스 결과 문자열
 */
function changeInvoiceResultToKr(
  invoiceResult: InvoiceResult | undefined | null
) {
  if (invoiceResult === "complete") {
    return "완료";
  }

  if (invoiceResult === "over") {
    return "초과";
  }

  if (invoiceResult === "unpaid") {
    return "미수";
  }
  return "";
}

/**
 * 인보이스 결과에 따라 색상을 반환합니다.
 *
 * @param invoiceResult - 인보이스 결과 (InvoiceResult 타입)
 * @returns 인보이스 결과에 따른 색상 문자열
 */
function getInvoiceResultColor(
  invoiceResult: InvoiceResult | undefined | null
) {
  if (invoiceResult === "unpaid") {
    return "red";
  }
  return "primary";
}

/**
 * 정산 BL 번호를 반환합니다.
 *
 * @param management - TrelloBidManagement 객체의 일부 속성 (BLType, hBL, mBL)
 * @returns 정산 BL 번호 문자열
 * @remarks management 객체가 없거나 BLType이 없으면 "-"를 반환합니다.
 */
function getSettlementBLNumber(
  management: Pick<TrelloBidManagement, "BLType" | "hBL" | "mBL">
) {
  if (!management) return "-";

  const { BLType, hBL, mBL } = management;

  if (!BLType) return "-";

  if (BLType === "MBL" || BLType === "DirectMBL") return `[MBL: ${mBL}]`;

  return `[HBL: ${hBL}]`;
}

/**
 * 인보이스 결과에 따라 매칭 상태를 반환합니다.
 *
 * @param invoiceResult - 인보이스 결과 (InvoiceResult 타입)
 * @returns 인보이스 결과에 따른 매칭 상태 문자열
 */
function getInvoiceMatchedStatus(
  invoiceResult: InvoiceResult | undefined | null
) {
  if (invoiceResult === "unpaid") {
    return "미수";
  }
  return "완료";
}

export {
  changeInvoiceResultToKr,
  getSettlementBLNumber,
  getInvoiceResultColor,
  getInvoiceMatchedStatus,
};

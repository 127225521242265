import { TeamRole } from "../../../types/common/team";
import { TeamStatus } from "../../../types/forwarding/adminTeam";

/**
 * 팀 상태를 한국어로 변환하는 함수
 *
 * @param status - 팀 상태 (TeamStatus 타입 또는 undefined)
 * @returns 팀 상태에 해당하는 한국어 문자열
 *
 * @remarks
 * 만약 status가 undefined이거나 매칭되는 상태가 없으면 "-"를 반환합니다.
 */
function changeTeamStatusToKr(status: TeamStatus | undefined) {
  switch (status) {
    case "delete":
      return "삭제";
    case "pause":
      return "중지";
    case "normal":
      return "정상";
    default:
      return "-";
  }
}

/**
 * 팀 역할을 한국어로 변환하는 함수
 *
 * @param status - 팀 역할 (TeamRole 타입)
 * @returns 팀 역할에 해당하는 한국어 문자열
 */
function changeTeamRoleToKr(status: TeamRole) {
  switch (status) {
    case "master":
      return "팀 마스터";
    case "manager":
      return "팀 관리자";
    case "staff":
      return "팀원";
  }
}

export { changeTeamStatusToKr, changeTeamRoleToKr };

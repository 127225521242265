import { atom } from "recoil";

import { ExporterInfo } from "../../../types/common/common";

const IS_LOADED_ALL_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOAD_ALL_TEMPLATE_DATA",
  default: false,
});

const IS_LOADED_LOCAL_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOADED_LOCAL_TEMPLATE_DATA",
  default: false,
});

const IS_LOADED_FREIGHT_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOADED_FREIGHT_TEMPLATE_DATA",
  default: false,
});

const IS_LOADED_DOMESTIC_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOADED_DOMESTIC_TEMPLATE_DATA",
  default: false,
});

const IS_LOADED_INLAND_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOADED_INLAND_TEMPLATE_DATA",
  default: false,
});

const IS_LOADED_OTHER_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOADED_OTHER_TEMPLATE_DATA",
  default: false,
});

const IS_LOADED_TAX_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminBid/atoms/IS_LOADED_TAX_TEMPLATE_DATA",
  default: false,
});

const ADMIN_BID_EXPORTER_ID = atom<number>({
  key: "forwarding/adminBid/atoms/ADMIN_BID_EXPORTER_ID",
  default: 0,
});

const ADMIN_BID_EXPORTER_INFO = atom<ExporterInfo>({
  key: "forwarding/adminBid/atoms/ADMIN_BID_EXPORTER_INFO",
  default: {
    companyName: "",
    personEmail: "",
    personName: "",
    personPhone: "",
  },
});

const ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY = atom<number>({
  key: "forwarding/adminTrello/atoms/ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY",
  default: 0,
});

export default {
  IS_LOADED_ALL_TEMPLATE_DATA,
  IS_LOADED_LOCAL_TEMPLATE_DATA,
  IS_LOADED_FREIGHT_TEMPLATE_DATA,
  IS_LOADED_DOMESTIC_TEMPLATE_DATA,
  IS_LOADED_INLAND_TEMPLATE_DATA,
  IS_LOADED_OTHER_TEMPLATE_DATA,
  IS_LOADED_TAX_TEMPLATE_DATA,
  ADMIN_BID_EXPORTER_ID,
  ADMIN_BID_EXPORTER_INFO,
  ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY,
};

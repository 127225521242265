import {
  CustomsManager,
  PartnerListItem,
  PartnerManagerItem,
} from "../../../types/forwarding/partner";

/**
 * 주어진 값에 따라 국가 파라미터를 변경합니다.
 *
 * @param value - 변경할 값
 * @returns 변경된 국가 파라미터
 */
export const changeFetchCountryParam = (value: string) => {
  if (value === "전체") {
    return undefined;
  } else if (value === "전 세계") {
    return "ALL";
  } else {
    return value;
  }
};

export const GLOBELINK_SHANGHAI_FCL_PARTNER_ID = 952;

export const FARECON_FOREIGN_PARTNER_ID = 967;

export const GLOBELINKSHA_PARTNER_ID = 607;

export const GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID = 136;

export const FARECON_DOMESTIC_PARTNER_ID = 120;

export const GLOBELINK_KOREA_PARTNER_ID = 612;

/**
 * 통관 파트너와 통관 매니저 정보를 조합하여 문자열로 반환합니다.
 *
 * @param customsPartner - 통관 파트너 정보
 * @param customsManager - 통관 매니저 정보
 * @returns 통관 파트너와 매니저 정보를 포함한 문자열
 */
export const getCustomsPartnerInfo = (
  customsPartner: PartnerListItem,
  customsManager: CustomsManager
) => {
  if (!customsPartner) {
    return "";
  }
  return `${customsPartner.name} (${customsManager?.name || "이름 없음"}, ${
    customsManager?.email || "이메일 없음"
  })`;
};

/**
 * 매니저 리스트에서 주 매니저를 찾습니다.
 *
 * @param managerList - 매니저 리스트
 * @returns 주 매니저 정보
 */
export const findMainManager = (managerList: PartnerManagerItem[]) => {
  return managerList.find((managerItem) => {
    return managerItem.isMain;
  });
};

/**
 * 주 매니저의 특정 정보를 반환합니다.
 *
 * @param type - 반환할 정보의 타입 ("name", "position", "phone", "email")
 * @param managerList - 매니저 리스트
 * @returns 주 매니저의 특정 정보
 */
export const getMainManagerInfo = (
  type: "name" | "position" | "phone" | "email",
  managerList: PartnerManagerItem[]
) => {
  const mainManagerItem = findMainManager(managerList);

  if (!mainManagerItem) {
    return "";
  }
  return mainManagerItem[type];
};

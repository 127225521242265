import { UserRegisterType, UserType } from "../../../types/common/user";
import { AdminGuideType } from "../../../types/forwarding/adminBidUser";

import { AdminBidUserFreightType } from "../../../api-interfaces/shipda-api/adminBidUser";

/**
 * 주어진 사용자 유형을 한국어로 변환합니다.
 *
 * @param type - 사용자 유형 (UserType)
 * @returns 한국어로 변환된 사용자 유형
 */
const changeUserTypeToKr = (type: UserType) => {
  switch (type) {
    case "forwarder":
      return "물류";
    case "customs":
      return "관세";
    case "consignee":
      return "수입";
    default:
      return "-";
  }
};

/**
 * 주어진 사용자 등록 유형을 한국어로 변환합니다.
 *
 * @param type - 사용자 등록 유형 (UserRegisterType)
 * @returns 한국어로 변환된 사용자 등록 유형
 */
const changeUserRegisterTypeToKr = (type: UserRegisterType) => {
  switch (type) {
    case "externalChannel":
      return "외부채널";
    case "fclPromotion":
      return "F타사견적서-100불";
    default:
      return "구분 없음";
  }
};

/**
 * 주어진 사용자 화물 유형을 한국어로 변환합니다.
 *
 * @param type - 사용자 화물 유형 (AdminBidUserFreightType | null)
 * @returns 한국어로 변환된 사용자 화물 유형
 */
const changeUserDBFreightTypeToKr = (type: AdminBidUserFreightType | null) => {
  switch (type) {
    case "FCL":
      return "FCL";
    case "oceanTicket":
      return "오션티켓";
    case "consolidation":
      return "특송";
    case "fulfillment":
      return "풀필먼트";
    case "export":
      return "수출";
    default:
      return "구분 없음";
  }
};

/**
 * 주어진 가이드 유형을 한국어로 변환합니다.
 *
 * @param type - 가이드 유형 (AdminGuideType)
 * @returns 한국어로 변환된 가이드 유형
 */
const getUserGuideName = (type: AdminGuideType) => {
  switch (type) {
    case "oceanTicketGuide":
      return "오션티켓 가이드";
    case "consolidationGuide":
      return "묶음운송 가이드";
    case "importProcessGuide":
      return "수입 프로세스 가이드";
    case "customerAttrition":
      return "가이드북 희망";
    case "CIPLSample":
      return "CI/PL 샘플";
    case "CIPLGuide":
      return "CI/PL 가이드";
    case "boful":
      return "보풀 가이드";
    case "fulfillmentIntroduction":
      return "쉽다 풀필먼트 소개서";
    case "fulfillmentEstimation":
      return "쉽다 풀필먼트 표준견적서";
    case "fulfillmentGuide":
      return "풀필먼트 소개서, 표준견적서 둘다";
    case "fulfillmentEventEstimation":
      return "쉽다 풀필먼트 이벤트견적서";
    case "fulfillmentEventGuide":
      return "풀필먼트 소개서, 표준견적서 둘다";
    case "serviceGuide":
      return "소개서";
    case "advancedServiceGuide":
      return "쉽다 서비스 소개서";
    case "serviceGuideBook":
      return "가이드북";
    case "allServiceGuide":
      return "소개서 모두 받기";
    case "GDC":
      return "GDC";
    default:
      return "-";
  }
};

/**
 * 주어진 가이드 유형에 따라 필드 카테고리를 반환합니다.
 *
 * @param type - 가이드 유형 (AdminGuideType)
 * @returns 필드 카테고리 (풀필먼트 또는 포워딩)
 *
 * @remarks
 * 풀필먼트 관련 가이드 유형은 "풀필먼트"를 반환하고, 그 외의 경우 "포워딩"을 반환합니다.
 */
const getUserGuideFieldCategory = (type: AdminGuideType): string => {
  const fulfillmentGuideList: AdminGuideType[] = [
    "boful",
    "fulfillmentEstimation",
    "fulfillmentEventEstimation",
    "fulfillmentEventGuide",
    "fulfillmentGuide",
    "fulfillmentIntroduction",
  ];

  return fulfillmentGuideList.includes(type) ? "풀필먼트" : "포워딩";
};

export {
  changeUserTypeToKr,
  changeUserRegisterTypeToKr,
  changeUserDBFreightTypeToKr,
  getUserGuideName,
  getUserGuideFieldCategory,
};
